import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonText,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import styled from 'styled-components';

import WithSecurityLevel from '../../pages/SubMenu/SubItemList/WithSecurityLevel';
import { useAuth0 as useAuth } from '@auth0/auth0-react';
import { useI18n, ImageViewer } from '@echtech/iron-core';

import './Menu.css';
import { appPages } from './MenuData';
import { useCurrentContact } from '@echtech/iron-core';

const Menu: React.FC = () => {
  const [showPopover, setShowPopover] = useState(false);

  const { logout } = useAuth();
  const contact = useCurrentContact();
  const { t } = useI18n('menuTranslations');

  const supplier = contact.supplier;
  const imageUrl = supplier.imageUrl;

  return (
    <IonMenu contentId="main" type="overlay">
      <IonToolbar color="primary">
        <IonItem
          color="primary"
          lines="none"
          className="inner-padding-end-less"
        >
          <div slot="start" onClick={() => setShowPopover(true)}>
            <Image className="readonly" src={imageUrl} />
          </div>
          <IonLabel>
            <div>
              <b>{contact?.name}</b>
            </div>
            <SupplierNameText color="light">{supplier?.name}</SupplierNameText>
          </IonLabel>
        </IonItem>
      </IonToolbar>

      <ImageViewer
        src={imageUrl}
        isOpen={showPopover}
        onDismiss={() => setShowPopover(false)}
      />

      <IonContent>
        <IonList>
          {appPages.map((appPage, index) => {
            return (
              <WithSecurityLevel
                key={index}
                pageAccessLevel={appPage.accessLevel}
                pageSubscriptionLevel={appPage.subscriptionLevel}
                isPageAccessLevelNegativeOneException={
                  appPage?.isPageAccessLevelNegativeOneException
                }
              >
                <IonMenuToggle autoHide={false}>
                  <IonItem
                    className="light-shade-background"
                    routerDirection="root"
                    detail={false}
                    lines="none"
                    routerLink={appPage.url}
                    onClick={
                      appPage.isLogout
                        ? () =>
                            logout({
                              logoutParams: {
                                returnTo: window.location.origin,
                              },
                            })
                        : undefined
                    }
                  >
                    <IonIcon
                      slot="start"
                      ios={appPage.iosIcon}
                      md={appPage.mdIcon}
                    />
                    <IonLabel>{t(appPage.titleKey)}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              </WithSecurityLevel>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

const SupplierNameText = styled(IonText)`
  font-size: 0.9rem;
`;

const Image = styled.img`
  border-radius: 50%;
  height: 50px;
  width: 50px;
`;
