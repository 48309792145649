import {
  homeOutline,
  cartOutline,
  storefrontOutline,
  listOutline,
  logOutOutline,
  calendarOutline,
  settingsOutline,
} from 'ionicons/icons';

export interface MenuItem {
  iosIcon?: string;
  mdIcon?: string;
  titleKey: string;
  url: string;
  accessLevel: number;
  subscriptionLevel: number;
  isPageAccessLevelNegativeOneException?: boolean;
  // special marker to allow calling the logout function obtained from useAuth hook elsewhere
  isLogout?: boolean;
}

export const appPages: MenuItem[] = [
  {
    accessLevel: 0,
    iosIcon: homeOutline,
    mdIcon: homeOutline,
    subscriptionLevel: 0,
    titleKey: 'home.title',
    url: '/',
  },
  {
    accessLevel: 1,
    iosIcon: '../assets/icon/inventories.svg',
    mdIcon: '../assets/icon/inventories.svg',
    subscriptionLevel: 0,
    titleKey: 'inventories.title',
    url: '/subMenu/inventories',
  },
  {
    accessLevel: 0,
    iosIcon: cartOutline,
    mdIcon: cartOutline,
    subscriptionLevel: 0,
    titleKey: 'purchases.title',
    url: '/subMenu/purchases',
  },
  {
    accessLevel: 0,
    iosIcon: storefrontOutline,
    mdIcon: storefrontOutline,
    subscriptionLevel: 0,
    titleKey: 'sales.title',
    url: '/subMenu/sales',
  },
  {
    accessLevel: 2,
    iosIcon: '../assets/icon/bankBillFinance.svg',
    mdIcon: '../assets/icon/bankBillFinance.svg',
    subscriptionLevel: 0,
    titleKey: 'credits.title',
    url: '/subMenu/credits',
  },
  {
    accessLevel: -1,
    iosIcon: '../assets/icon/service.svg',
    mdIcon: '../assets/icon/service.svg',
    subscriptionLevel: 0,
    titleKey: 'deliveries.title',
    url: '/subMenu/deliveries',
  },
  {
    accessLevel: 0,
    iosIcon: listOutline,
    mdIcon: listOutline,
    subscriptionLevel: 0,
    titleKey: 'tasks.title',
    url: '/subMenu/tasks',
  },
  {
    accessLevel: 0,
    iosIcon: '../assets/icon/chefHat.svg',
    mdIcon: '../assets/icon/chefHat.svg',
    subscriptionLevel: 2,
    titleKey: 'production.title',
    url: '/subMenu/production',
  },
  {
    accessLevel: 0,
    iosIcon: calendarOutline,
    mdIcon: calendarOutline,
    subscriptionLevel: 0,
    titleKey: 'administration.title',
    url: '/subMenu/administration',
  },
  {
    accessLevel: 1,
    iosIcon: settingsOutline,
    mdIcon: settingsOutline,
    subscriptionLevel: 0,
    titleKey: 'settings.title',
    url: '/subMenu/settings',
  },
  {
    accessLevel: -1,
    isPageAccessLevelNegativeOneException: true,
    iosIcon: logOutOutline,
    mdIcon: logOutOutline,
    subscriptionLevel: 0,
    titleKey: 'logout.title',
    isLogout: true,
    url: '/',
  },
];
